/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: 'Willkommen im Coworking-Space Oppenweiler',
  subTitle: 'Work, Connect, and Sweat',
  logo_name: "",
  body: "Erleben Sie die perfekte Kombination aus Produktivität und Fitness in unserem Coworking-Space." +
        "\nBleiben Sie aktiv, bleiben Sie konzentriert und erreichen Sie Ihre beste Work-Life-Balance." + 
        "\n\nKatja Diehl die doppelte Preisträgerin des Deutschen Mobilitätspreises fordert mehr Coworking Spaces auf dem Land. " +
        "Wir nehmen sie beim Wort und schaffen die Möglichkeit zum wohnortnahen Arbeiten." +
        "\n\nSchluss mit Pendeln und Verschwendung von Zeit und Geld auf dem Arbeitsweg." +
        "\nUnd die Natur dankt es uns auch!",
  mail: "mailto:info@coworking-oppenweiler.de",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  mail: "info@coworking-oppenweiler.de"
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Us",
    profile_image_path: "",
    mobilephone: "+49 151 5535 5572",
    address: "Gabweg 32 71570 Oppenweiler",
    description:
      "You can contact us at the places mentioned below. We will try to get back to you as fast as we can. "
  }
};

export {
  settings,
  greeting,
  socialMediaLinks,
  contactPageData
};
