import React from "react";
import "./Footer.css";
import { greeting } from "../../portfolio.js";
/* eslint-disable jsx-a11y/accessible-emoji */

export default function Footer(props) {
  return (
    <div className="footer-div">
      <p className="footer-text" style={{ color: props.theme.secondaryText }}>
        
      </p>
      <p className="footer-text" style={{ color: props.theme.secondaryText }}>
        Made with <span role="img">❤️</span>
      </p>
    </div>
  );
}
