import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import "./ContactComponent.css";
import { contactPageData } from "../../portfolio.js";
import GetMapWithCoworkingLocation from "../../components/misc/GoogleMaps";

const ContactData = contactPageData.contactSection;

function Contact(props) {
  const theme = props.theme;

  return (
    <div className="contact-main">
      <Header theme={theme} setTheme={props.setTheme} />
      <div className="basic-contact">
        <div className="contact-heading-div">
          <div className="contact-heading-text-div">
            <h1 className="contact-heading-text" style={{ color: theme.text }}>
              {ContactData["title"]}
            </h1>
            <p
              className="contact-header-detail-text subTitle"
              style={{ color: theme.secondaryText }}
            >
              {ContactData["description"]}
            </p>
            <address
              className="contact-header-detail-text subTitle"
              style={{ color: theme.secondaryText }}
            >
              {ContactData.address}
            </address>
            <p
              className="contact-header-detail-text subTitle"
              style={{ color: theme.secondaryText }}
            >
            <a href={"tel:" + ContactData.mobilephone}>{ContactData.mobilephone}</a>
            </p>
            <SocialMedia />
            <div>
              <GetMapWithCoworkingLocation>
                "Coworking Oppenweiler - Gabweg 32 - 71570 Oppenweiler"
              </GetMapWithCoworkingLocation>
            </div>
          </div>
        </div>
      </div>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Contact;
