import React from "react";
import "./FeelingProud.css";

function FeelingProud(props) {
  return (
    <picture>
      <source style={{float: "right"}} srcset={require("../../assests/images/open-space-coworking.webp")} width="500" />
      <img 
        style={{float: "right"}}
        src={require("../../assests/images/open-space-coworking.png")} 
        alt="Coworking-Space"
        width="500" />
    </picture>
  );
}

export default FeelingProud;
