import React from "react";

export default function GetMapWithCoworkingLocation() {
  return (
    <iframe
      title="Coworking Oppenweiler"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2617.861025736306!2d9.459672999999999!3d48.994202099999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4798355f132d8e73%3A0x617a91c0c57538bb!2sGabweg%2032%2C%2071570%20Oppenweiler!5e0!3m2!1sde!2sde!4v1674401295117!5m2!1sde!2sde"
      width="600"
      height="400"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  );
}
