// Impressum.js

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import React from 'react';

const Impressum = (props) => {
const theme = props.theme;
const contentStyle = {
    textAlign: 'center'
  };

  return (
    <div>
        
    <Header theme={theme} setTheme={props.setTheme} />
    <div>
        <div style={contentStyle}>
        <h1>Impressum</h1>

        <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
        <p>Isabell Wingerter<br />
        Gabweg 32<br />
        71570 Oppenweiler</p>

        <h2>Kontakt</h2>
        <p>Telefon: +49 (0) 151 55355 572<br />
        
        E-Mail: <a href="mailto:info@coworking-oppenweiler.de">info@coworking-oppenweiler.de</a></p>

        <h2>Redaktionell verantwortlich</h2>
        <p>Isabell Wingerter<br />
        Gabweg 32<br />
        71570 Oppenweiler</p>

        <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
        </div>
    </div>
        
  );
}

export default Impressum;